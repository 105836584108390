import styled from 'styled-components'
import { Icon, Text } from '@it25syv/25syv-ui'

export const MenuWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: ${({ background }) => background || 'white'};
  color: black;
  z-index: ${({ zIndexWrapper }) => zIndexWrapper + 1 || 112};
  box-shadow: 0 0 10px #0e449a33;
  border-radius: 20px 20px 0 0;
  padding: 12px 22px;
  ${({ transition }) =>
    transition && 'transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1)'};
  @media screen and (min-width: 768px) {
    max-width: 400px;
    right: 0;
  }
`

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
export const DragLabels = styled.div`
  display: flex;
`
export const DragLabel = styled.div`
  background: ${({ color }) => color || 'white'};
  width: 20px;
  height: 4px;
  position: relative;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    left: 1px;
    ${({ deg }) => `transform: rotate(${deg}deg)`};
  }

  &:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    right: 1px;
    ${({ deg }) => `transform: rotate(${-deg}deg)`};
  }
`
export const Title = styled(Text)`
  margin: 15px auto;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  font-size: 22px;
`
export const MenuItems = styled.div`
  padding: 16px 6px;
  height: calc(100% - 150px);
  ${({ isOpen }) => isOpen && 'overscroll-behavior: contain'};
  overflow-y: ${({ fullTop }) => (fullTop ? 'auto' : 'none')};
`

export const MenuItemWrapper = styled.div`
  background: ${({ MenuItemsColor }) =>
    MenuItemsColor || 'rgba(255, 255, 255, 0.12)'};
  border-radius: 10px;
  margin-bottom: 16px;
`
export const MenuItem = styled.div`
  padding: 17px 20px;
  font-family: 'Roboto Condensed', sans-serif;
  color: white;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  p {
    margin: 0 10px 0 20px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 100;
  }
`

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const MenuItemIcon = styled(Icon)`
  @media screen and (min-width: 768px) {
    width: 25px;
    height: 25px;
  }
`
export const DefaultMenuItems = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;

  ${MenuItemWrapper} {
    width: 48%;
    text-align: center;
  }

  ${MenuItem} {
    padding: 10px;
    flex-direction: column;

    p {
      margin: 8px 0 0;
    }
  }

  svg {
    margin-top: 8px;
  }
`
