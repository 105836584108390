import React, { Fragment, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Icon, Text } from '@it25syv/25syv-ui'
import Menu from './Menu'
import { ReactComponent as MoreIcon } from '../images/more.svg'
import { ReactComponent as CrossIcon } from '../images/close.svg'
import PropTypes from 'prop-types'

const hide = keyframes`
  100% {
    bottom: 0;
  }
`

const Wrapper = styled.div`
  z-index: ${({ zIndexWrapper }) => zIndexWrapper || 111};
  position: fixed;
  overflow: scroll;
  overflow-x: hidden;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      animation: ${hide} 0s ease-in 300ms forwards;
    `}
  height: ${({ isOpen }) => (isOpen ? '100%' : '60px')};
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, ${({ isOpen }) => (isOpen ? '0.5' : '0')});
  ${({ isOpen }) =>
    css`
      transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1),
        transform 300ms cubic-bezier(0.4, 0, 0.2, 1),
        position 300ms cubic-bezier(0.4, 0, 0.2, 1)
          ${!isOpen && `, height 0ms ease 300ms`};
    `};
`

const MenuWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${({ zIndexWrapper }) => zIndexWrapper + 2 || 113};
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 0 10px #0e449a33;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
`

const Container = styled.div`
  margin: 0;
  background: ${({ background }) => background || '#fff'};
  display: flex;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  overflow: hidden;
  padding: 0 7px;
  height: 60px;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  @media (min-width: 768px) {
    padding: 0 60px;
  }
`

const MenuItem = styled.div`
  font-size: 12px;
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 6px 0;
  margin-top: 2px;
  box-sizing: border-box;

  width: 60px;
  height: 50px;

  ${({ disabled }) =>
    disabled &&
    css`
      ${NavIcon}, ${StyledText}, ${Badge} {
        opacity: 0.5;
      }
    `};

  ${({ active }) =>
    active &&
    css`
      //background: rgba(255, 255, 255, 0.1);
      border-radius: 5px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -6px;
        left: 0;
        width: 100%;
        height: 4px;
        background: #ffd804;
        border-radius: 4px;
      }
    `};
`

const iconStyles = css`
  width: 18px;
  height: 18px;
  margin-bottom: 5px;
  @media (min-width: 768px) {
    width: 25px;
    height: 25px;
  }
`

const NavIcon = styled(Icon)`
  ${iconStyles};
`
const StyledText = styled(Text)`
  font-size: 10px;
  margin: 0;
  line-height: 1.3;
  text-align: center;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  text-decoration: none;
  font-family: 'Roboto Condensed', sans-serif;
  @media (min-width: 768px) {
    font-size: 12px;
  }
`

const Badge = styled.div`
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 10px;
  color: ${({ color }) => color};
  border-radius: 100%;
  width: 13px;
  height: 13px;
  position: absolute;
  top: 0;
  left: 35px;
  background: white;
  margin: 0;
  padding: 0;
  line-height: normal;
`

const BottomMenu = ({ config }) => {
  const [menuState, setMenuState] = useState(false)
  const [moreActive, setMoreActive] = useState(false)

  const {
    backgroundColor,
    secondaryColor,
    initialMenuPosition,
    menuItems,
    dropDownMenuItems,
    zIndexWrapper,
    initiallyFullTop,
    MenuItemsColor
  } = config

  useEffect(() => {
    setMoreActive(
      dropDownMenuItems.uniqItemsActive &&
        !menuItems.some((item) => !!item.active)
    )
  }, [window.location.href, config])

  const openMenu = () => {
    setMenuState(!menuState)
  }

  return (
    <Wrapper
      isOpen={menuState}
      zIndexWrapper={zIndexWrapper}
      onClick={(e) => {
        if (menuState && e.target === e.currentTarget) {
          setMenuState(false)
        }
      }}
    >
      <MenuWrapper zIndexWrapper={zIndexWrapper}>
        {config ? (
          <Container background={backgroundColor}>
            {menuItems.map((item, index) => (
              <MenuItem
                key={`bottom-menu-${index}`}
                active={!menuState && item.active}
                disabled={item.disabled}
                onClick={() => {
                  if (!item.disabled) {
                    item.action()
                    setMenuState(false)
                  }
                }}
              >
                <NavIcon src={item.icon} color={'white'} />
                <StyledText
                  color={secondaryColor || 'white'}
                  short
                  align={'center'}
                >
                  {item.title}
                </StyledText>
                {!!item.badge && (
                  <Badge color={backgroundColor}>{item.badge}</Badge>
                )}
              </MenuItem>
            ))}
            <MenuItem onClick={openMenu} active={menuState || moreActive}>
              {menuState ? (
                <Fragment>
                  <CrossIcon
                    style={{
                      verticalAlign: 'middle',
                      marginBottom: '5px',
                      width: 18,
                      height: 18
                    }}
                  />
                  <StyledText
                    color={secondaryColor || 'white'}
                    short
                    align={'center'}
                  >
                    Luk
                  </StyledText>
                </Fragment>
              ) : (
                <Fragment>
                  <MoreIcon
                    style={{
                      verticalAlign: 'middle',
                      marginBottom: '5px',
                      width: 18,
                      height: 18
                    }}
                  />
                  <StyledText
                    color={secondaryColor || 'white'}
                    short
                    align={'center'}
                  >
                    Mere
                  </StyledText>
                </Fragment>
              )}
            </MenuItem>
          </Container>
        ) : null}
      </MenuWrapper>
      <Menu
        isOpen={menuState}
        configs={dropDownMenuItems}
        background={backgroundColor}
        secondaryColor={secondaryColor}
        initialMenuPosition={initialMenuPosition}
        zIndexWrapper={zIndexWrapper}
        initiallyFullTop={initiallyFullTop}
        onClose={() => {
          setMenuState(false)
        }}
        MenuItemsColor={MenuItemsColor}
      />
    </Wrapper>
  )
}

BottomMenu.propTypes = {
  config: PropTypes.shape({
    backgroundColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    initialMenuPosition: PropTypes.number,
    initiallyFullTop: PropTypes.bool,
    zIndexWrapper: PropTypes.number,
    MenuItemsColor: PropTypes.string,
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        icon: PropTypes.string,
        action: PropTypes.func,
        active: PropTypes.bool,
        disabled: PropTypes.bool,
        badge: PropTypes.number
      })
    ),
    dropDownMenuItems: PropTypes.object
  })
}

export default BottomMenu
